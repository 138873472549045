import React, { FC, useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import SectionSliderCollections from "components/SectionSliderLargeProduct";
import SectionPromo1 from "components/SectionPromo1";
import ProductCard from "components/ProductCard";
import ProductCardNew from "components/ProductCardNew";
import { PRODUCTS } from "data/data";
import SidebarFilters from "./SidebarFilters";
import Pagination from "shared/Pagination/PaginationNew";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Categories, getAllProducts } from "Services/API/Get";
import _ from "lodash";
import LoadingSpinner from "shared/LoadingSpinner";
import { useLocation } from "react-router-dom";
import ProductCardNew2 from "components/ProductCardNew2";
import localStorage from "redux-persist/es/storage";

export interface PageCollection2Props {
  className?: string;
}

const PageCollection2: FC<PageCollection2Props> = ({ className = "" }) => {
  const location = useLocation();
  const path = location.pathname;

  const [nProducts, setnProducts] = useState([]);
  const [allSizes, setallSizes] = useState<any | null>([]);
  const [allColours, setallColours] = useState<any | null>([]);
  const [allSubs, setallSubs] = useState<any | null>([]);
  const [categories, setcategories] = useState([]);
  const [tags, settags] = useState<any | null>([]);
  const [selectedSize, setselectedSize] = useState("");
  const [selectedColour, setselectedColour] = useState("");
  const [selectedCat, setselectedCat] = useState<any | null>();
  const [minPrice, setminPrice] = useState<any | null>(0);
  const [maxPrice, setmaxPrice] = useState<any | null>(999);
  const [selectedSub, setselectedSub] = useState<any>("");

  const [selectedSubCat, setselectedSubCat] = useState([]);
  const [dataCount, setcount] = useState(0);
  const [search, setsearch] = useState("");
  const [lopen, setlopen] = useState(true);

  const [mainDesc, setmainDesc] = useState<any>("");
  const [subDesc, setsubDesc] = useState("");
  const [filterSortValue, setFilterSortValue] = useState("");
  const [filterSortIndex, setfilterSortIndex] = useState(0);

  const [pageLimit, setpageLimit] = useState(68);
  const [currentPage, setCurrentPage] = useState(1);

  const [Scat, setScat] = useState(false);

  const [allProd, setallProd] = useState<any | null>([]);

  const [search2, setsearch2] = useState("");

  const getallCategory = () => {
    Categories()
      .then((response) => {
        setcategories(response.data);
        // this.setState(
        //   {
        //     array: res.data.result,
        //     filter: res.data.result,
        //     showPages: m,
        //     showOff: true,
        //   },
        //   async () => {
        //     await this.getPaginationGroup(m);
        //   }
        // );
      })
      .catch(() => {
        // this.setState({
        //   array: [],
        //   filter: [],
        //   showPages: 1,
        //   showOff: false,
        // });
      });
  };


  
  React.useEffect(() => {
    const checkSelectedCat = async () => {
      const selectedCategory = await localStorage.getItem("sMain"); // Wait for the Promise to resolve
      console.log("checknull", selectedCategory)
   
   
      // const selectedSubCategory = await localStorage.getItem("sSub"); // Wait for the Promise to resolve
      if (selectedCategory !== null && !isNaN(parseInt(selectedCategory, 10))) {
  
        setselectedCat(parseInt(selectedCategory, 10)); // Parse to integer
      } else {
      
        setselectedCat(4)
         localStorage.setItem("sMain", '4');
      }
      // if (selectedSubCategory !== null ) {
      //   setselectedSub(parseInt(selectedSubCategory, 10)); // Parse to integer
      // }
    };
  
    checkSelectedCat(); // Call the async function
  }, []);

  useEffect(() => {
    getallCategory();
    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth"
    // });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth"
      // });
    }, 1500);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      // if (!isNaN(selectedCat)) {
        const data = await getFilters();
        // const data2 = await getItemProducts2();
      // }
    };
  
    fetchData();
  }, [selectedCat]);
  
  useEffect(() => {
    // if (allSubs.length !== 0) {
      const fetchData = async () => {
        const data2 = await getItemProducts2();
        
      };

      fetchData();


    // }
  }, [
    selectedCat,
    selectedSub,
    allSubs,
    filterSortValue,
    pageLimit,
    currentPage,
    selectedColour,
    selectedSize,
    minPrice,
    maxPrice,
    search
  ]);


  const getFilters = async () => {
  
    const ob = {
      sort: {
        sortField: "productName",
        sort: "asc"
      },
      categories:
        selectedCat === "" ? JSON.stringify([]) : JSON.stringify([selectedCat]),
        subId:
      selectedSub === "" || isNaN(selectedSub) ?  JSON.stringify([]) : JSON.stringify([selectedSub]),
   
      distinct: "0"
    };
    // setlopen(true);

    await getAllProducts(ob)
      .then((res) => {
       
        // const allProd = res.data.result

        // setallProd(allProd);

        const allColors = _.uniqBy(
          res?.data?.result,
          function (e: { clolour: any }) {
            return e.clolour;
          }
        );
        var allColors2 = allColors?.filter(function (el: any) {
          return el.clolour !== "";
        });
        const allSizes = _.uniqBy(
          res?.data?.result,
          function (e: { size: any }) {
            return e.size;
          }
        );
        var sizeArr = allSizes?.filter(function (el: { size: string }) {
          return el.size !== "";
        });
        const allPrice = _.uniqBy(
          res?.data?.result,
          function (e: { discountedprice: any }) {
            return e.discountedprice;
          }
        );
        const allTags = _.uniqBy(
          res?.data?.result,
          function (e: { tags: any }) {
            return e.tags;
          }
        );
        const allSubs = _.uniqBy(
          res?.data?.result,
          function (e: { subcatId: any; subCategory: any; subcatDesc: any }) {
            return e.subCategory;
          }
        );


        var subsArr = allSubs?.filter(function (el: { subCategory: null }) {
          return el.subCategory !== null;
        });
        
        const myPrices = _.map(allPrice, "discountedprice");

        const Pricelist = _.sortBy(myPrices, [
          function (o: any) {
            return o;
          }
        ]);

        const mySizes = _.map(sizeArr, "size");
        const myColors = _.map(allColors2, "clolour");
        const myTags = _.map(allTags, "tags");
        const mySubs = _.map(subsArr, "subCategory");

        const updateMyColors = myColors?.map((item: any, index: any) => {
          return {
            id: index,
            name: item,
            status: false
          };
        });

        const updateMySubs = subsArr.map((item, index) => {
          return {
            id: item.subcatId,
            name: item.subCategory
            // description: item.subcatDesc,
          };
        });
        setallSizes(mySizes);
        setallColours(myColors);
        settags(myTags);
        setallSubs(updateMySubs);

        let Id2: any = localStorage.getItem("sSub");
        setselectedSub(Id2 ? parseInt(Id2) : "");
        // this.setState({
        //   color: updateMyColors,
        //   size: mySizes,
        //   min: Pricelist[0],
        //   max: Pricelist[Pricelist.length - 1],
        //   range: {
        //     min: Pricelist[0],
        //     max: Pricelist[Pricelist.length - 1],
        //   },
        // });
        setlopen(false);
      })
      .catch(() => {
        setlopen(false);
      });
  };

  const getFilters2 = async () => {
    const ob = {
      sort: {
        sortField: "productName",
        sort: "asc"
      },
      categories: JSON.stringify([]),
      distinct: "1",
      search: search
    };
    // setlopen(true);

    await getAllProducts(ob)

      .then((res) => {
        
        const allProd = res.data.result;

        setallProd(allProd);
      })
      .catch(() => {
        setlopen(false);
      });
  };

  useEffect(() => {
    getFilters2();
  }, [search]);

  const getItemProducts = async () => {
    let sort = {
      sortField: "productName",
      sort: "asc"
    };
    let latest = "";
    let featured = "";

    if (filterSortValue === "HighToLow") {
      sort = {
        sortField: "unitPrice",
        sort: "desc"
      };
    } else if (filterSortValue === "LowToHigh") {
      sort = {
        sortField: "unitPrice",
        sort: "asc"
      };
    } else if (filterSortValue === "latest") {
      latest = "1";
    } else if (filterSortValue === "featured") {
      featured = "1";
    } else {
      sort = {
        sortField: "productName",
        sort: "asc"
      };
      latest = "";
      featured = "";
    }


    const obj = {
      sort: sort,
      categories:
        selectedCat === "" ? JSON.stringify([]) : JSON.stringify([selectedCat]),
        subId: 
        selectedSub === "" || isNaN(selectedSub) ? JSON.stringify([]) : JSON.stringify([selectedSub]),
    
      search: search,
      latest: latest,
      distinct: "1",
      featured: featured,
      pNo: currentPage.toString(),
      limit: pageLimit.toString(),
      color: selectedColour,
      size: selectedSize,
      pto: maxPrice,
      pfrom: minPrice
    };
    try {
      await getAllProducts(obj)
        .then((response) => {
          // setlopen(false);
          let m =
            Math.ceil(parseFloat(response.data.count[0].pages)) === 0
              ? 1
              : Math.ceil(parseFloat(response.data.count[0].pages));

          setnProducts(response.data.result);
          setcount(response.data.count[0].count);
          // this.setState(
          //   {
          //     array: res.data.result,
          //     filter: res.data.result,
          //     showPages: m,
          //     showOff: true,
          //   },
          //   async () => {
          //     await this.getPaginationGroup(m);
          //   }
          // );
        })
        .catch(() => {
          setnProducts([]);
          setcount(0);
          // setlopen(false);
          // this.setState({
          //   array: [],
          //   filter: [],
          //   showPages: 1,
          //   showOff: false,
          // });
        });
    } catch (error) {
      console.log("T&C ", error);
    }
  };
console.log("sadjsiodhaosdysoaidyhioashd",allSubs)
  const getItemProducts2 = async () => {
    let sort = {
      sortField: "productName",
      sort: "asc"
    };
    let latest = "";
    let featured = "";

    if (filterSortValue === "HighToLow") {
      sort = {
        sortField: "unitPrice",
        sort: "desc"
      };
    } else if (filterSortValue === "LowToHigh") {
      sort = {
        sortField: "unitPrice",
        sort: "asc"
      };
    } else if (filterSortValue === "latest") {
      latest = "1";
    } else if (filterSortValue === "featured") {
      featured = "1";
    } else {
      sort = {
        sortField: "productName",
        sort: "asc"
      };
      latest = "";
      featured = "";
    }

    // console.log(sortType)

    const obj = {
      sort: sort,
      categories:
        selectedCat === "" ? JSON.stringify([]) : JSON.stringify([selectedCat]),
      subId:
      selectedSub === "" || isNaN(selectedSub) ?  JSON.stringify([]) : JSON.stringify([selectedSub]),
      search: search,
      distinct: "1",
      latest: latest,
      featured: featured,
      pNo: currentPage.toString(),
      limit: pageLimit.toString(),
      color: selectedColour,
      size: selectedSize,
      pto: maxPrice,
      pfrom: minPrice
    };
    try {
      // setlopen(true);
      await getAllProducts(obj)
        .then((response) => {
          setlopen(false);

          let m =
            Math.ceil(parseFloat(response.data.count[0].pages)) === 0
              ? 1
              : Math.ceil(parseFloat(response.data.count[0].pages));

          setnProducts(response.data.result);
          setcount(response.data.count[0].count);
          // this.setState(
          //   {
          //     array: res.data.result,
          //     filter: res.data.result,
          //     showPages: m,
          //     showOff: true,
          //   },
          //   async () => {
          //     await this.getPaginationGroup(m);
          //   }
          // );
        })
        .catch(() => {
          setnProducts([]);
          setcount(0);
          setlopen(false);
          // this.setState({
          //   array: [],
          //   filter: [],
          //   showPages: 1,
          //   showOff: false,
          // });
        });
    } catch (error) {
      console.log("T&C ", error);
    }
  };



  useEffect(() => {

    let Id: any = localStorage.getItem("sMain");
    let Id2: any = localStorage.getItem("sSub");

    if (
      Id &&
      Id !== undefined &&
      // || (Id2 && Id2 !== undefined)
      categories.length !== 0 &&
      nProducts.length !== 0
    ) {
      console.log("swa",Id)
      // setselectedCat(parseInt(Id));
      // setselectedSub(Id2 ? parseInt(Id2) : "");

      Categories()
        .then((response) => {
          var subsArr = response?.data?.filter(function (el: {
            mainId: number;
          }) {
            return el.mainId === parseInt(Id!);
          });

        
          setmainDesc(subsArr[0]);
          setScat(true);
        })
        .catch(() => {
          setScat(true);
        });
    }

    // return () => {
    //   localStorage.removeItem("sMain");
    // };
  }, [categories, nProducts]);



  // React.useEffect(() => {
  //   // if (selectedCat !== 4) {
  //     console.log("sfsdfgdgggggggggggggggggg", selectedSub)
  //     localStorage.setItem("sSub", selectedSub);
  //     localStorage.setItem("sMain", selectedCat);
  //   // }
  // }, [selectedCat,selectedSub]);


  




  const scrollPositionRef = useRef<number>(0);
  const handleLinkClick = () => {
    localStorage.setItem("shopposition", String(window.scrollY));
 
  };


  useEffect(() => {
    const restoreScrollPosition = async () => {
      const savedPosition = await localStorage.getItem("shopposition");
  
      // Check if the value is not null and convert it to a number
      if (savedPosition !== null) {
        console.log("Restoring scroll position:", savedPosition);
        setTimeout(() => {
          window.scrollTo(0, parseInt(savedPosition, 10));
        }, 1500); // Delay to ensure all content is loaded
      } else {
        window.scrollTo(0, 0); // Default to top if no saved position
      }
    };
  
    restoreScrollPosition();
  }, []);
  
  // Save the scroll position before the component unmounts or navigates away
  // useEffect(() => {
  //   const saveScrollPosition = () => {
  //     localStorage.setItem("shopposition", String(window.scrollY));
  //   };
  
  //   window.addEventListener("beforeunload", saveScrollPosition);
  //   return () => {
  //     window.removeEventListener("beforeunload", saveScrollPosition);
  //   };
  // }, []);
  

  return (
    <div
      className={`nc-PageCollection2 ${className}`}
      data-nc-id="PageCollection2"
    >
      <LoadingSpinner open={lopen} />
      <Helmet>
        <title>
          Cleaning products suppliers | Cleaning chemicals supplies Dandenong -
          Oz Cleaning Supplies
        </title>
        <meta
          name="description"
          content="Welcome to Oz Cleaning Supplies, one of the most reliable sources for commercial &
residential cleaning products suppliers and cleaning chemicals supplies in
Dandenong!"
        />

        <meta
          name="keywords"
          content="Cleaning products suppliers Dandenong, Cleaning chemicals supplies Dandenong"
        />
        <link rel="canonical" href={path + "/"} />

        <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Oz Cleaning Supplies",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au${path}"
}
    `}</script>
      </Helmet>

      <div className="container py-16 lg:pb-0 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          <div className="w-full">
            <h1 className="mb-8 text-2xl">
              Best Cleaning Products And Cleaning Chemicals Supplies Dandenong
            </h1>
            <h2 className="block text-xl sm:text-2xl lg:text-3xl font-semibold">
              {/* Man collection */}
              {mainDesc?.maincategory}
            </h2>
            <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-xs sm:text-sm text-left">
              {/* We not only help you design exceptional products, but also make it
              easy for you to share your designs with more like-minded people. */}
              {mainDesc?.description}
            </span>
          </div>

          <hr className="border-slate-200 dark:border-slate-700" />

          <main>
            {/* LOOP ITEMS */}
            <div className="flex flex-col lg:flex-row">
              <div className="lg:w-1/3 xl:w-1/4 pr-4">
                <SidebarFilters
                  categories={categories}
                  setSelectedcat={setselectedCat}
                  selectedCat={selectedCat}
                  allSubs={allSubs}
                  setselectedSub={setselectedSub}
                  selectedSub={selectedSub}
                  allSizes={allSizes}
                  selectedSize={selectedSize}
                  setselectedSize={setselectedSize}
                  allColours={allColours}
                  selectedColour={selectedColour}
                  setselectedColour={setselectedColour}
                  setminPrice={setminPrice}
                  minPrice={minPrice}
                  setmaxPrice={setmaxPrice}
                  maxPrice={maxPrice}
                  filterSortValue={filterSortValue}
                  setFilterSortValue={setFilterSortValue}
                  setCurrentPage={setCurrentPage}
                  setmainDesc={setmainDesc}
                  setsearch={setsearch}
                  search={search}
                  allProd={allProd}
                  search2={search2}
                  setsearch2={setsearch2}
                />
              </div>
              <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mx-4 border-t lg:border-t-0"></div>
              <div className="flex-1 ">
                <div className="flex-1 grid grid-cols-2 sm:grid-cols-2 xl:grid-cols-4 gap-x-8 gap-y-10 ">
                  {nProducts?.length > 0 &&
                    nProducts?.map((item, index) => (
                      <ProductCardNew2 handleLinkClick={handleLinkClick} data2={item} key={index} />
                    ))}
                  {nProducts.length === 0 && (
                    <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-xs sm:text-sm">
                      No Products found.
                    </span>
                  )}
                </div>
                {/* PAGINATION */}
                <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
                  <Pagination
                    pageLimit={pageLimit}
                    dataCount={dataCount}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                  <ButtonPrimary
                    className="bg-[#74BC1F]"
                    // loading
                    onClick={() => {
                      setpageLimit(pageLimit + 3);
                    }}
                  >
                    Show me more
                  </ButtonPrimary>
                </div>
              </div>
            </div>
          </main>
        </div>

        {/* === SECTION 5 === */}
        {/* <hr className="border-slate-200 dark:border-slate-700" />

        <SectionSliderCollections /> */}
        <hr className="border-slate-200 dark:border-slate-700" />

        {/* SUBCRIBES */}
      </div>
      <div className="container space-y-4 mb-8 lg:my-10">
        <p>
          Welcome to Oz Cleaning Supplies, your premier destination for
          top-quality Cleaning products and cleaning chemicals supplies in
          Dandenong. We understand the importance of maintaining a clean and
          hygienic environment, whether it's for your home, office, or
          commercial space. With our extensive range of cleaning supplies and
          chemicals, we make it easier than ever to keep your surroundings
          sparkling clean.
        </p>

        <p>
          As one of the leading cleaning product suppliers in Dandenong, we take
          pride in offering a diverse selection of products to meet your
          specific needs. From multi-purpose cleaners to specialized chemicals,
          we have everything you need to tackle any cleaning task with ease. Our
          products are sourced from trusted manufacturers known for their
          reliability and effectiveness, ensuring that you get the best results
          every time.
        </p>

        <p>
          At Oz Cleaning Supplies, we prioritize customer satisfaction above all
          else. Our knowledgeable team is always on hand to provide expert
          advice and guidance, helping you find the perfect solutions for your
          cleaning challenges. Whether you're a professional cleaner or a
          homeowner looking to maintain a pristine living space, we have the
          products and expertise to support you every step of the way.
        </p>

        <p>
          Experience the difference that quality cleaning products can make in
          your life. Shop with confidence at Oz Cleaning Supplies, your trusted
          partner for all your cleaning product and cleaning chemical needs in
          Dandenong
        </p>
      </div>
      <SectionPromo1 />
    </div>
  );
};

export default PageCollection2;
